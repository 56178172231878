
import { defineComponent, reactive, ref } from '@vue/composition-api'

import { useAuthGetters, useNotify } from '@/store'

import { Contact, ContactLoggedIn } from '@/api/types/contact'
import { useSendMessage } from '@/api/contact'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired, isEmail } from '@/utils/validation'

export default defineComponent({
  name: 'ContactForm',
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const { isAuthenticated } = useAuthGetters()

    const { sendMessage: sendMessageXhr } = useSendMessage()

    const formElement = ref<ValidationForm | null>(null)
    const isFormValid = ref(false)

    function createFormData(): Contact | ContactLoggedIn {
      const formData = {
        subject: '',
        message: '',
      }

      if (isAuthenticated.value) return formData
      else
        return {
          ...formData,
          subject: '',
          message: '',
        }
    }

    const formData: Contact | ContactLoggedIn = reactive(createFormData())

    async function sendMessage(): Promise<void> {
      if (formElement.value?.validate()) {
        await sendMessageXhr(formData)

        addNotification({
          text: root.$t('contact.messageSent.success') as string,
          type: 'success',
        })

        formElement.value.reset()
        formElement.value.resetValidation()
      }
    }

    return reactive({
      formElement,
      state: {
        isAuthenticated,

        isFormValid,
        formData,
      },
      functions: {
        sendMessage,
      },
      formRules: {
        isRequired,
        isEmail,
      },
    })
  },
})

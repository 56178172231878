var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElement",
    model: {
      value: _vm.state.isFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFormValid", $$v);
      },
      expression: "state.isFormValid"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.state.isAuthenticated ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.formRules.isRequired(_vm.state.formData.name, _vm.$t('contact.name'))],
      "label": _vm.$t('contact.name')
    },
    model: {
      value: _vm.state.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.state.formData, "name", $$v);
      },
      expression: "state.formData.name"
    }
  }) : _vm._e(), !_vm.state.isAuthenticated ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.formRules.isRequired(_vm.state.formData.mailAddress, _vm.$t('contact.mail')), _vm.formRules.isEmail(_vm.state.formData.mailAddress, _vm.$t('contact.mail'))],
      "label": _vm.$t('contact.mail')
    },
    model: {
      value: _vm.state.formData.mailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.state.formData, "mailAddress", $$v);
      },
      expression: "state.formData.mailAddress"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "rules": [_vm.formRules.isRequired(_vm.state.formData.subject, _vm.$t('contact.subject'))],
      "label": _vm.$t('contact.subject')
    },
    model: {
      value: _vm.state.formData.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.state.formData, "subject", $$v);
      },
      expression: "state.formData.subject"
    }
  }), _c('v-textarea', {
    attrs: {
      "rules": [_vm.formRules.isRequired(_vm.state.formData.message, _vm.$t('contact.message'))],
      "label": _vm.$t('contact.message')
    },
    model: {
      value: _vm.state.formData.message,
      callback: function callback($$v) {
        _vm.$set(_vm.state.formData, "message", $$v);
      },
      expression: "state.formData.message"
    }
  }), _c('v-btn', {
    staticClass: "mt-5 rounded-lg",
    attrs: {
      "color": "primary",
      "disabled": !_vm.state.isFormValid
    },
    on: {
      "click": function click($event) {
        return _vm.functions.sendMessage();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('contact.send')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }

import { defineComponent } from '@vue/composition-api'

import ContactForm from '@/views/contact/components/ContactForm.vue'

export default defineComponent({
  name: 'ContactView',
  components: {
    ContactForm,
  },
})

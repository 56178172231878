var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    staticClass: "pa-3",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg elevation-0",
    attrs: {
      "width": "50%"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 primary white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('contact.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('ContactForm')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }